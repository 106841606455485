// 获取更多查询字段列表
export const getMoreQueryFormData = (that, formModel) => {
  const moreQueryData = [
    // 消息编号
    {
      name: 'requestId',
      span: 12,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.interface.requestId')
    },
    // 请求体
    {
      name: 'requestBody',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.interface.requestBody'),
      data: that.requestBodyList
    },
    // 执行接口
    {
      name: 'execInterface',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.interface.execInterface'),
      filterable: true,
      data: that.execInterfaceList
    },
    // 执行命令
    {
      name: 'execInstruction',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.interface.execInstruction'),
      filterable: true,
      data: that.execInstructionList
    },
    // 通讯状态
    {
      name: 'requestStatus',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.interface.requestStatus'),
      filterable: true,
      data: that.requestStatusList
    },
    // 单据状态
    {
      name: 'receiptStatus',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.interface.receiptStatus'),
      filterable: true,
      data: that.receiptStatusList
    },
    // 应答代码
    {
      name: 'responseCode',
      span: 12,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.interface.responseCode')
    },
    // 外部单据号
    {
      name: 'hostOrderCode',
      span: 12,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.interface.hostOrderCode')
    },
    // 容器号
    {
      name: 'containerCode',
      span: 12,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.containerCode')
    },
    // 机器人编号
    {
      name: 'robotCode',
      span: 12,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.interface.robotCode')
    },
    {
      name: 'timeRange',
      span: 24,
      component: 'elDatePicker',
      type: 'datetimerange',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.interface.timeRange'),
      mchange: that.handleTimeChange
    }
  ]
  moreQueryData.forEach((item) => {
    if (!item.mchange) {
      item.mchange = that.onFormChange
    }
  })
  return moreQueryData
}

// 查询列表列表
export const getSearchTableItem = (that) => {
  const searchTableItem = [
    // 消息编号
    {
      prop: 'requestId',
      label: `${that.$t('lang.gles.interface.requestId')}`,
      isShow: true,
      minWidth: 200
    },
    // 请求主体
    {
      prop: 'requestBody',
      label: that.$t('lang.gles.interface.requestBody'),
      isShow: true
    },
    // 接口名称
    {
      prop: 'interfaceName',
      label: `${that.$t('lang.gles.interface.interfaceName')}`,
      isShow: true
    },
    // 执行接口
    {
      prop: 'execInterface',
      label: `${that.$t('lang.gles.interface.execInterface')}`,
      isShow: true
    },
    // 执行命令
    {
      prop: 'execInstruction',
      label: `${that.$t('lang.gles.interface.execInstruction')}`,
      isShow: true
    },
    // 通讯状态
    {
      prop: 'requestStatusName',
      label: `${that.$t('lang.gles.interface.requestStatus')}`,
      isShow: true,
      slotName: 'requestStatusSlot'
    },
    // 单据状态
    {
      prop: 'receiptStatusName',
      label: `${that.$t('lang.gles.interface.receiptStatus')}`,
      isShow: true,
      slotName: 'receiptStatusSlot'
    },
    // 应答代码
    {
      prop: 'responseCode',
      label: `${that.$t('lang.gles.interface.responseCode')}`,
      isShow: true
    },
    // 外部单据号
    {
      prop: 'hostOrderCode',
      label: `${that.$t('lang.gles.interface.hostOrderCode')}`,
      isShow: true
    },
    // 容器编码
    {
      prop: 'containerCode',
      label: `${that.$t('lang.gles.base.containerCode')}`,
      isShow: true
    },
    // 机器人编号
    {
      prop: 'robotCode',
      label: `${that.$t('lang.gles.interface.robotCode')}`,
      isShow: true
    },
    // 请求时间
    {
      prop: 'requestTime',
      label: `${that.$t('lang.gles.common.requestTime')}`,
      isShow: true,
      minWidth: 180
    },
    // 响应时间
    {
      prop: 'responseTime',
      label: `${that.$t('lang.gles.common.responseTime')}`,
      isShow: true,
      minWidth: 180
    }
  ]
  searchTableItem.forEach((item) => (item.showOverflowTooltip = true))
  return searchTableItem
}

export const tableData = [
  {
    id: 1,
    requestId: '2307ef4a-520c-423f-be8a-e58db1aa6d00',
    requestAddress: 'www.baidu.com',
    requestBody: 'g-les',
    interfaceName: '单据回调',
    execInterface: 'OrderCallbackMsg',
    execInstruction: '',
    requestMessage: null,
    requestTime: '2022-01-08 18:01:32',
    requestStatus: 1,
    requestStatusName: '异常',
    responseTime: null,
    responseCode: null,
    responseMessage: null,
    orderCode: 'orderCode1234',
    hostOrderCode: 'hostOrderCode2134',
    receiptStatus: 0,
    receiptStatusName: '正常',
    containerCode: 'containerCode1234',
    robotCode: 'robotCode12345'
  },
  {
    id: 2,
    requestId: '34452992-7162-41ea-99ac-afadac8d585f',
    requestAddress: 'www.baidu.com',
    requestBody: 'g-les',
    interfaceName: '单据回调',
    execInterface: 'OrderCallbackMsg',
    execInstruction: '',
    requestMessage: null,
    requestTime: '2022-01-08 18:01:32',
    requestStatus: 1,
    requestStatusName: '异常',
    responseTime: null,
    responseCode: null,
    responseMessage: null,
    orderCode: 'orderCode1234',
    hostOrderCode: 'hostOrderCode2134',
    receiptStatus: 0,
    receiptStatusName: '正常',
    containerCode: 'containerCode1234',
    robotCode: 'robotCode12345'
  },
  {
    id: 3,
    requestId: 'aff90606-51e6-46eb-a42b-f08dfe58d3ae',
    requestAddress: 'www.baidu.com',
    requestBody: 'g-les',
    interfaceName: '单据回调',
    execInterface: 'OrderCallbackMsg',
    execInstruction: '',
    requestMessage: null,
    requestTime: '2022-01-08 18:01:33',
    requestStatus: 1,
    responseTime: null,
    responseCode: null,
    responseMessage: null,
    orderCode: 'orderCode1234',
    hostOrderCode: 'hostOrderCode2134',
    receiptStatus: 0,
    containerCode: 'containerCode1234',
    robotCode: 'robotCode12345'
  },
  {
    id: 4,
    requestId: 'b84fe07e-3301-4062-90ce-e2c264774235',
    requestAddress: 'www.baidu.com',
    requestBody: 'g-les',
    interfaceName: '单据回调',
    execInterface: 'OrderCallbackMsg',
    execInstruction: '',
    requestMessage: null,
    requestTime: '2022-01-08 18:01:34',
    requestStatus: 1,
    responseTime: null,
    responseCode: null,
    responseMessage: null,
    orderCode: 'orderCode1234',
    hostOrderCode: 'hostOrderCode2134',
    receiptStatus: 0,
    containerCode: 'containerCode1234',
    robotCode: 'robotCode12345'
  },
  {
    id: 5,
    requestId: 'b172ce7d-7df8-4742-a204-d02b53bbce8e',
    requestAddress: 'www.baidu.com',
    requestBody: 'g-les',
    interfaceName: '单据回调',
    execInterface: 'OrderCallbackMsg',
    execInstruction: '',
    requestMessage: null,
    requestTime: '2022-01-08 18:01:35',
    requestStatus: 1,
    responseTime: null,
    responseCode: null,
    responseMessage: null,
    orderCode: 'orderCode1234',
    hostOrderCode: 'hostOrderCode2134',
    receiptStatus: 0,
    containerCode: 'containerCode1234',
    robotCode: 'robotCode12345'
  }
]
