<template>
  <div class="basic-data-list">
    <query-view
      ref="myQueryView"
      name="requestId"
      :value.sync="baseFormModel.requestId"
      :placeholder="$t('lang.gles.interface.pleaseInputMessageCode')"
      :query-list="moreQueryData"
      @baseSearch="handleBaseSearch"
      @moreSearch="handleMoreSearch"
    />
    <el-card class="table-card">
      <div class="operator-wrapper">
        <!-- 列设置 -->
        <column-config :list.sync="columnConfigList" />
      </div>
      <m-table
        :table-item="tableItem"
        :table-data="tableData"
        :page-data="pageData"
        :extend-config="tableExtendConfig"
        @pageChange="pageChange"
        @viewItem="handleView"
      >
        <template #enableStatus="{ row }">
          <el-switch
            :value="row.enableStatus"
            class="switch"
            :active-text="$t('lang.gles.common.enable')"
            :inactive-text="$t('lang.gles.common.disable')"
            :active-value="0"
            :inactive-value="1"
            @click.native="handleChangeEnableStatus(row)"
          />
        </template>
        <template #receiptStatusSlot="{ row }">
          <span class="status">
            <i
              class="ui-dot-process"
              :style="{ '--dotColor': getStatusColor(row.receiptStatus) }"
            />
            {{ row.receiptStatusName }}
          </span>
        </template>
        <template #requestStatusSlot="{ row }">
          <span class="status">
            <i
              class="ui-dot-process"
              :style="{ '--dotColor': getStatusColor(row.requestStatus) }"
            />
            {{ row.requestStatusName }}
          </span>
        </template>
      </m-table>
    </el-card>
    <m-dialog :visible.sync="dialogVisible" :is-need-footer="false">
      <p v-if="requestMessage">
        {{ $t('lang.gles.interface.requestMessage') }}：
      </p>
      <pre style="background: #1e1e1e; color: #fff; margin-top: 10px">{{
        requestMessage
      }}</pre>
      <p v-if="responseMessage">
        {{ $t('lang.gles.interface.responseMessage') }}：
      </p>
      <pre style="background: #1e1e1e; color: #fff; margin-top: 10px">{{
        responseMessage
      }}</pre>
    </m-dialog>
  </div>
</template>
<script>
import MTable from '@/libs_sz/components/MTable/MTable'
import QueryView from '@/components/moreQuery/queryView.vue'
import ColumnConfig from '@/components/columnConfig/index.vue'
import { getMoreQueryFormData, getSearchTableItem } from '../data'
import * as types from '@/store/mutationTypes'
import { mapState } from 'vuex'
import listMixins from '@/mixins/listMixins'
import dayjs from 'dayjs'

export default {
  name: 'InterfaceLogList',
  components: {
    MTable,
    QueryView,
    ColumnConfig
  },
  mixins: [listMixins],
  data() {
    return {
      tableData: [],
      baseFormModel: {},
      formModel: {},
      tableExtendConfig: {
        sortNum: true,
        operateWidth: '60px',
        operate: [
          {
            event: 'viewItem',
            label: this.$t('lang.gles.common.view')
          }
        ]
      },
      dialogVisible: false,
      requestMessage: '',
      responseMessage: '',
      requestBodyList: [],
      execInterfaceList: [],
      execInstructionList: []
    }
  },
  computed: {
    ...mapState('interfaces', ['requestStatusList', 'receiptStatusList']),
    moreQueryData() {
      return getMoreQueryFormData(this, { ...this.formModel })
    },
    /**
     * 默认的表格列表项
     */
    defaultTableItem() {
      return getSearchTableItem(this)
    },
    /**
     * 渲染的表格列表项  列设置
     */
    tableItem() {
      let list = this.defaultTableItem
      if (this?.columnConfigList?.length) {
        list = this.defaultTableItem.filter(
          (item, i) =>
            item.name === this.columnConfigList[i]?.name &&
            this.columnConfigList[i]?.isShow
        )
      }
      return list
    }
  },
  async created() {
    this.initSelectList()
    // this.tableData = tableData
    this.doSearch()
  },
  methods: {
    /**
     * 初始化下拉列表
     */
    async initSelectList() {
      const { data, code } = await this.$httpService(
        this.$apiStore.interfaces('getSelectOperation')
      )
      if (code) return
      const { execInstruction, clientCode, execInterface } = data || {}
      this.execInstructionList = execInstruction.map((t) => {
        return {
          value: t,
          label: t
        }
      })
      this.requestBodyList = clientCode.map((t) => {
        return {
          value: t,
          label: t
        }
      })
      this.execInterfaceList = execInterface.map((t) => {
        return {
          value: t,
          label: t
        }
      })
    },
    /**
     * 接口查询
     */
    async doSearch(params, api = 'getInterfaceLogList') {
      try {
        const { data, code } = await this.$httpService(
          this.$apiStore.interfaces(api, this.pageData),
          params
        )
        this.$store.commit(types.IS_LOADING, false)
        if (code) return
        const {
          recordList = [],
          currentPage,
          pageSize,
          totalRecordCount: recordCount
        } = data || {}
        this.pageData = { currentPage, pageSize, recordCount }
        const list = recordList.map((item) => {
          return {
            ...item,
            requestStatusName:
              this.requestStatusList?.find(
                (t) => t.value === item.requestStatus
              )?.label ?? '',
            receiptStatusName:
              this.receiptStatusList?.find(
                (t) => t.value === item.receiptStatus
              )?.label ?? ''
          }
        })
        this.tableData = list || []
      } catch (error) {
        this.$store.commit(types.IS_LOADING, false)
      }
    },
    /**
     * 状态 颜色
     * @param {*} state
     */
    getStatusColor(state) {
      return state === 0 ? '#00c07b' : '#e72f38'
    },
    /**
     * 时间范围
     * @param {*} va
     */
    handleTimeChange(val) {
      if (val) {
        this.formModel.startTime = dayjs(val[0]).format('YYYY-MM-DD HH:mm:ss')
        this.formModel.endTime = dayjs(val[1]).format('YYYY-MM-DD HH:mm:ss')
      }
    },
    /**
     * 基础查询
     */
    handleBaseSearch(data) {
      this.doSearch({ ...data })
    },
    /**
     * 更多查询
     */
    handleMoreSearch(data) {
      this.formModel = {
        ...this.formModel,
        ...data
      }
      delete this.formModel.timeRange
      this.doSearch({ ...this.formModel })
    },
    async handleView({ row }) {
      const { data, code } = await this.$httpService(
        this.$apiStore.interfaces('getInterfaceLogDetail'),
        { id: row.id }
      )
      this.$store.commit(types.IS_LOADING, false)
      if (code) return
      let { requestMessage, responseMessage } = data || {}
      if (requestMessage) {
        requestMessage = JSON.parse(requestMessage)
        this.requestMessage = JSON.stringify(requestMessage, null, '\t')
      }
      if (responseMessage) {
        responseMessage = JSON.parse(responseMessage)
        this.responseMessage = JSON.stringify(responseMessage, null, '\t')
      }
      this.dialogVisible = true
    }
  }
}
</script>
<style lang="scss" scoped>
.ui-dot-process {
  background: var(--dotColor);
  &:after {
    border-color: var(--dotColor);
  }
}
</style>
